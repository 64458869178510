<template>
    <!--  书信往来-->
    <div class="letter">
        <ul>
            <li @click="linkToDetail(item)" v-for="(item,index) in contentList" :key="index">
                <div class="time">{{item.data}}</div>
                <div class="title">{{item.news_title}}</div>
            </li>
        </ul>
        <!-- 分页 -->
        <div class="pageBar">
            <div class="block">
                <el-pagination
                    :pager-count="5"
                    @current-change="handleCurrentChange"
                    background
                    :page-size="page.per_page"
                    layout=" prev, pager, next"
                    :total="page.total"
                    :prev-text="$t('preText')"
                    :next-text="$t('nextText')"
                    :current-page="page.current_page"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
// import { getTimeBeforeDay } from "../../common/js/common";
import { getCateList, getLetterList } from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            cateId: "",
            page: {
                total: 0,
                per_page: 20,
                current_page: 1,
                last_page: 1
            },
            contentList: [
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // },
                // {
                //     news_title: "關於力量的一點體會",
                //     data: "2019-09-19"
                // }
            ]
        };
    },
    methods: {
        handleCurrentChange(e) {
            this.page.current_page = e;
            this.getLetter();
        },
        linkToDetail(item) {
			window.open(this.$BaseURL+'/articleDetail?name=letter&id='+item.id);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "letter", id: item.id }
            // }); //跳转到心得详情
        },
        //获取展示数据
        getLetter() {
            getLetterList(this.cateId, {
                limit: this.page.per_page,
                page: this.page.current_page
            }).then(res => {
                this.page.total = res.total;
                this.page.current_page = res.current_page;
                this.page.last_page = res.last_page;
                // this.contentList = getTimeBeforeDay(res.data, "data");
                this.contentList = res.data;
            });
        }
    },
    created() {
        getCateList().then(res => {
            this.cateId = res[2][1].id;
            this.getLetter();
        });
    },
    destroyed(){
        this.page.current_page = 1
        console.log(this.page.current_page)
    }
};
</script>

<style lang="stylus" scoped>
.pageBar {
    margin-top: 42px;
    text-align: center;
}

.letter {
    width: 700px;
    font-size: 15px;
    margin: 20px;
    text-align: left;

    li {
        width: 100%;
        /* height: 40px;
        line-height: 40px; */
		height: 32px;
        line-height: 32px;
        border-bottom: 1px dashed #D4D4D4;

        div {
            display: inline-block;
        }

        .time {
            width: 120px;
        }

        .title {
            width: 580px;
        }
    }

    li:hover {
        cursor: pointer;
        color: #1482d9;
    }
}
</style>