import { render, staticRenderFns } from "./letter.vue?vue&type=template&id=765b2bae&scoped=true&"
import script from "./letter.vue?vue&type=script&lang=js&"
export * from "./letter.vue?vue&type=script&lang=js&"
import style0 from "./letter.vue?vue&type=style&index=0&id=765b2bae&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765b2bae",
  null
  
)

export default component.exports